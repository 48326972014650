/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeletePaymentsCommand } from '../models/delete-payments-command';
import { EmployerRelationshipData } from '../models/employer-relationship-data';
import { ExportPaymentSummaryQuery } from '../models/export-payment-summary-query';
import { ExportPaymentsQuery } from '../models/export-payments-query';
import { ImportPaymentCommand } from '../models/import-payment-command';
import { ImportedLineResult } from '../models/imported-line-result';
import { ListPaymentsQuery } from '../models/list-payments-query';
import { ListPaymentsResult } from '../models/list-payments-result';
import { PaymentSchedule } from '../models/payment-schedule';
import { PaymentType } from '../models/payment-type';
import { UserState } from '../models/user-state';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation paymentList
   */
  static readonly PaymentListPath = '/api/Payment/List';

  /**
   * Fizetések listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentList$Plain$Response(params?: {
    body?: ListPaymentsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListPaymentsResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPaymentsResult>;
      })
    );
  }

  /**
   * Fizetések listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentList$Plain(params?: {
    body?: ListPaymentsQuery
  },
  context?: HttpContext

): Observable<ListPaymentsResult> {

    return this.paymentList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListPaymentsResult>) => r.body as ListPaymentsResult)
    );
  }

  /**
   * Fizetések listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentList$Response(params?: {
    body?: ListPaymentsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListPaymentsResult>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListPaymentsResult>;
      })
    );
  }

  /**
   * Fizetések listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentList(params?: {
    body?: ListPaymentsQuery
  },
  context?: HttpContext

): Observable<ListPaymentsResult> {

    return this.paymentList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListPaymentsResult>) => r.body as ListPaymentsResult)
    );
  }

  /**
   * Path part for operation paymentPost
   */
  static readonly PaymentPostPath = '/api/Payment/Post';

  /**
   * Fizetés létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  paymentPost$Response(params?: {
    body?: {
'Id'?: number;
'RequestUserId'?: number;
'ProfilePicture'?: string;
'UserId'?: number;
'MCode'?: string;
'Name'?: string;
'UserState'?: UserState;
'EmployerId'?: number;
'Employer.Id'?: number;
'Employer.Name'?: string;
'Employer.Active'?: boolean;
'Employer.Email'?: string;
'Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'RecruiterId'?: number;
'Recruiter.Id'?: number;
'Recruiter.Name'?: string;
'Recruiter.Active'?: boolean;
'Recruiter.Email'?: string;
'Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'EmployerRelationshipId'?: number;
'EmployerRelationship.Id'?: number;
'EmployerRelationship.Name'?: string;
'EmployerRelationship.Active'?: boolean;
'Date'?: string;
'DateUtc'?: string;
'TimeZoneId'?: string;
'LabelId'?: number;
'Label'?: string;
'Company'?: string;
'EventInfo'?: string;

/**
 * Funkció
 */
'Function'?: string;

/**
 * Utazási idő
 */
'TravelTime'?: number;

/**
 * Úti bér [Ft/óra]
 */
'TravelCostPerHour'?: number;

/**
 * Utazási bér
 */
'TravelCost'?: number;

/**
 * Távolság
 */
'Distance'?: number;

/**
 * Úti bér [Ft/km]
 */
'TravelCostPerKm'?: number;

/**
 * Pontosság
 */
'Accuracy'?: number;

/**
 * Teljesítmény
 */
'Performance'?: number;

/**
 * Bérkód
 */
'WageCode'?: string;

/**
 * Órabér
 */
'HourlyWage'?: number;

/**
 * Munkaidő
 */
'WorkingHours'?: number;

/**
 * Munkabér
 */
'Wage'?: number;

/**
 * Fizetés
 */
'Payment'?: number;
'PaymentType'?: PaymentType;

/**
 * Megjegyzés
 */
'Description'?: string;
'PaymentSchedule'?: PaymentSchedule;
'CreateUserMCode'?: string;
'CreateUserProfilePicture'?: string;
'IsNotified'?: boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fizetés létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  paymentPost(params?: {
    body?: {
'Id'?: number;
'RequestUserId'?: number;
'ProfilePicture'?: string;
'UserId'?: number;
'MCode'?: string;
'Name'?: string;
'UserState'?: UserState;
'EmployerId'?: number;
'Employer.Id'?: number;
'Employer.Name'?: string;
'Employer.Active'?: boolean;
'Employer.Email'?: string;
'Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'RecruiterId'?: number;
'Recruiter.Id'?: number;
'Recruiter.Name'?: string;
'Recruiter.Active'?: boolean;
'Recruiter.Email'?: string;
'Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'EmployerRelationshipId'?: number;
'EmployerRelationship.Id'?: number;
'EmployerRelationship.Name'?: string;
'EmployerRelationship.Active'?: boolean;
'Date'?: string;
'DateUtc'?: string;
'TimeZoneId'?: string;
'LabelId'?: number;
'Label'?: string;
'Company'?: string;
'EventInfo'?: string;

/**
 * Funkció
 */
'Function'?: string;

/**
 * Utazási idő
 */
'TravelTime'?: number;

/**
 * Úti bér [Ft/óra]
 */
'TravelCostPerHour'?: number;

/**
 * Utazási bér
 */
'TravelCost'?: number;

/**
 * Távolság
 */
'Distance'?: number;

/**
 * Úti bér [Ft/km]
 */
'TravelCostPerKm'?: number;

/**
 * Pontosság
 */
'Accuracy'?: number;

/**
 * Teljesítmény
 */
'Performance'?: number;

/**
 * Bérkód
 */
'WageCode'?: string;

/**
 * Órabér
 */
'HourlyWage'?: number;

/**
 * Munkaidő
 */
'WorkingHours'?: number;

/**
 * Munkabér
 */
'Wage'?: number;

/**
 * Fizetés
 */
'Payment'?: number;
'PaymentType'?: PaymentType;

/**
 * Megjegyzés
 */
'Description'?: string;
'PaymentSchedule'?: PaymentSchedule;
'CreateUserMCode'?: string;
'CreateUserProfilePicture'?: string;
'IsNotified'?: boolean;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.paymentPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation paymentDelete
   */
  static readonly PaymentDeletePath = '/api/Payment/Delete';

  /**
   * Kijelölt fizetések törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentDelete$Response(params?: {
    body?: DeletePaymentsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentDeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt fizetések törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentDelete(params?: {
    body?: DeletePaymentsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.paymentDelete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation paymentImport
   */
  static readonly PaymentImportPath = '/api/Payment/Import';

  /**
   * Fizetések importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentImport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentImport$Plain$Response(params?: {
    body?: ImportPaymentCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedLineResult>>;
      })
    );
  }

  /**
   * Fizetések importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentImport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentImport$Plain(params?: {
    body?: ImportPaymentCommand
  },
  context?: HttpContext

): Observable<Array<ImportedLineResult>> {

    return this.paymentImport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedLineResult>>) => r.body as Array<ImportedLineResult>)
    );
  }

  /**
   * Fizetések importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentImport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentImport$Response(params?: {
    body?: ImportPaymentCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedLineResult>>;
      })
    );
  }

  /**
   * Fizetések importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentImport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentImport(params?: {
    body?: ImportPaymentCommand
  },
  context?: HttpContext

): Observable<Array<ImportedLineResult>> {

    return this.paymentImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedLineResult>>) => r.body as Array<ImportedLineResult>)
    );
  }

  /**
   * Path part for operation paymentExport
   */
  static readonly PaymentExportPath = '/api/Payment/Export';

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExport$Plain$Response(params?: {
    body?: ExportPaymentsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExport$Plain(params?: {
    body?: ExportPaymentsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExport$Response(params?: {
    body?: ExportPaymentsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExport(params?: {
    body?: ExportPaymentsQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation paymentCsv
   */
  static readonly PaymentCsvPath = '/api/Payment/Csv';

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentCsv$Plain$Response(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentCsvPath, 'get');
    if (params) {
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentCsv$Plain(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentCsv$Response(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentCsvPath, 'get');
    if (params) {
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentCsv(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation paymentExportSummary
   */
  static readonly PaymentExportSummaryPath = '/api/Payment/ExportSummary';

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExportSummary$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExportSummary$Plain$Response(params?: {
    body?: ExportPaymentSummaryQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExportSummary$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExportSummary$Plain(params?: {
    body?: ExportPaymentSummaryQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExportSummary$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExportSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExportSummary$Response(params?: {
    body?: ExportPaymentSummaryQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportSummaryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExportSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  paymentExportSummary(params?: {
    body?: ExportPaymentSummaryQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExportSummary$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation paymentExportSummaryCsv
   */
  static readonly PaymentExportSummaryCsvPath = '/api/Payment/ExportSummaryCsv';

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExportSummaryCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentExportSummaryCsv$Plain$Response(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
    MinimalWorkingHours?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportSummaryCsvPath, 'get');
    if (params) {
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('MinimalWorkingHours', params.MinimalWorkingHours, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExportSummaryCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentExportSummaryCsv$Plain(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
    MinimalWorkingHours?: number;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExportSummaryCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentExportSummaryCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentExportSummaryCsv$Response(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
    MinimalWorkingHours?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentService.PaymentExportSummaryCsvPath, 'get');
    if (params) {
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
      rb.query('DateFrom', params.DateFrom, {});
      rb.query('DateTo', params.DateTo, {});
      rb.query('MinimalWorkingHours', params.MinimalWorkingHours, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Fizetések összesítő exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentExportSummaryCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentExportSummaryCsv(params?: {
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    APIKey?: string;
    DateFrom?: string;
    DateTo?: string;
    MinimalWorkingHours?: number;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.paymentExportSummaryCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
